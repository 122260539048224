import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import { useQuery } from 'react-apollo';
import { useAddwish } from '@jetshop/flight-addwish';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import HelloRetailProductsQuery from './HelloRetailProductsQuery.gql';
import HelloRetailSection from './HelloRetailSection';

export const helloRetailKeys = {
  POPULAR_IN_CATEGORY: 'k5da9585f62e08256acb87beb',
  POPULAR_RIGHT_NOW: 'k5da9585f62e08256acb87be5',
  SIMILAR_PRODUCTS: 'k5da9585f62e08256acb87be9',
  ALSO_BOUGHT: 'k5da9585f62e08256acb87be6',
  RECOMMENDED_FOR_YOU: 'k5da9585f62e08256acb87bef'
};

export const HelloRetail = ({ helloRetailBoxes, children }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const { pathname } = useLocation();
  const boxData = { boxes: {}, allProductArticles: [], sections: [] };
  const hookBoxes = {};
  const hierarchies = pathname?.substring(1)?.split('/');

  for (const box of helloRetailBoxes) {
    boxData.boxes[box.key] = {
      productArticles: [],
      title: box.title
    };

    hookBoxes[box.key] = {
      url: `${selectedChannel.url}${pathname}`,
      hierarchies: [hierarchies]
    };
  }

  const loading = () => !boxData.sections.length > 0;

  const { addWishResponseBoxItems } = useAddwish({
    boxes: hookBoxes,
    pageLoaded: loading
  });

  for (const [boxKey, box] of Object.entries(addWishResponseBoxItems)) {
    if (box?.items.length > 0) {
      const productArticles = box.items.map(item => item.productNumber);

      boxData.boxes[boxKey].productArticles.push(...productArticles);
      boxData.allProductArticles.push(...productArticles);
    }
  }

  const addwishProductsQueryResult = useQuery(HelloRetailProductsQuery, {
    variables: {
      articleNumbers: boxData.allProductArticles
    },
    skip: boxData.allProductArticles.length === 0,
    errorPolicy: 'ignore'
  });

  // Outside loading fn to avoid unnecessary iteration
  if (addwishProductsQueryResult.loading) return null;

  const allProducts = addwishProductsQueryResult?.data?.products || [];

  for (const [boxKey, box] of Object.entries(boxData.boxes)) {
    const { productArticles } = box;
    const items = addWishResponseBoxItems?.[boxKey]?.items;

    if (productArticles.length !== 0) {
      boxData.sections.push({
        boxKey,
        title: box.title,
        products: allProducts
          .filter(({ articleNumber }) =>
            productArticles.includes(articleNumber)
          )
          ?.map(product => {
            return {
              ...product,
              addwishData: items?.find(
                item => item.productNumber === product.articleNumber
              )
            };
          })
      });
    }
  }

  if (!helloRetailBoxes) return null;

  if (!loading) {
    return null;
  } else {
    return children ? (
      <>
        {React.Children.map(children, child =>
          React.cloneElement(child, { boxes: boxData.sections })
        )}
      </>
    ) : (
      <>
        {boxData.sections.map(boxSection => (
          <HelloRetailSection
            key={boxSection.boxKey}
            helloRetailBox={boxSection}
          />
        ))}
      </>
    );
  }
};

export default HelloRetail;
