import React from 'react';
import ThemeLink from '../ThemeLink';

const Brand = props =>
  props.name && props.link ? (
    <li>
      <ThemeLink {...props} link={props.link}>
        {props.name}
      </ThemeLink>
    </li>
  ) : (
    <li>{props.name}</li>
  );

export default Brand;
