import React from 'react';
import { Above } from '@jetshop/ui/Breakpoints';
import { css } from 'linaria';
import { theme } from '../../Theme';
import ThemeImage from '../ThemeImage';

const OriginalBannerCSS = css`
  background: ${theme.colors.greyLight};
  > div img {
    object-fit: cover !important;
  }
`;

const Banner = props => {
  const BannerCSS = props.customCSS ? props.customCSS : OriginalBannerCSS;
  return (
    <div className={BannerCSS}>
      <Above breakpoint="md">
        {matches =>
          matches ? (
            <ThemeImage
              src={props.src ? props.src : props.url}
              {...props}
              aspect="4:1"
            >
              {props.children}
            </ThemeImage>
          ) : (
            <ThemeImage
              src={props.src ? props.src : props.url}
              {...props}
              aspect="3:2"
            >
              {props.children}
            </ThemeImage>
          )
        }
      </Above>
    </div>
  );
};

export default Banner;
