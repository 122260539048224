import React from 'react';
import HelloRetailSection from '../HelloRetail/HelloRetailSection';
import { Row } from './Grid';

const HelloRetailRow = ({ box, boxes }) => {
  if (!box || !boxes || !boxes[Number(box) - 1]) return false;

  const helloRetailBox = boxes[Number(box) - 1];

  return (
    <Row>
      <HelloRetailSection helloRetailBox={helloRetailBox} />
    </Row>
  );
};

export default HelloRetailRow;
