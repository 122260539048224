import React from 'react';
import { Link } from 'react-router-dom';
import { TrendLink } from '../ui/Button';

const ThemeLink = ({ link, children, isButton, ...rest }) => {
  if (link && link.charAt(0) === '/') {
    if (isButton) {
      return (
        <TrendLink {...rest} to={link}>
          {children}
        </TrendLink>
      );
    } else {
      return (
        <Link {...rest} to={link}>
          {children}
        </Link>
      );
    }
  } else {
    return (
      <a href={link} {...rest} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
};

export default ThemeLink;
