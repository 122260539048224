import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../../Theme';

const StyledRow = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  &.divider {
    padding-bottom: ${theme.space[8]};
    margin-bottom: 2rem;
    border-bottom: 1px solid rgba(225, 225, 225, 0.3);
  }

  &.full-width {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    display: block !important;
  }

  ${theme.below.md} {
    &.full-width-mobile {
      display: block;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
    }
    &.row {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  br {
    display: none;
  }
`;

const StyledColumn = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  width: ${p => `calc(${p.colWidth} - ${p.gap})`};

  &.fill {
    justify-content: stretch;
  }

  &.center {
    justify-content: center;
    > div {
      flex: 0 1 auto !important;
    }
  }

  > div {
    margin-bottom: 4px;
    ${theme.above.md} {
      margin-bottom: ${p => (p.hasMultipleItems ? '32px' : '0px')};
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${theme.below.md} {
    width: ${p => p.widthMobile};
    margin-bottom: ${p => p.itemGap ?? '4px'};
    margin-bottom: ${p => (p.items > 1 ? p.itemGap : '4px')};

    p {
      padding: 0 2rem;
    }
  }
`;

export const Row = ({
  children,
  fullwidth,
  fullwidthMobile,
  directionMobile = 'row',
  divider
}) => {
  return (
    <StyledRow
      className={cx(
        'grid-row',
        fullwidth && 'full-width',
        fullwidthMobile && 'full-width-mobile',
        directionMobile,
        divider && 'divider'
      )}
    >
      {children}
    </StyledRow>
  );
};

export const Column = ({
  children,
  colWidth = '50%',
  widthMobile = '100%',
  type,
  directionMobile = '',
  gap = '16px'
}) => {
  const hasMultipleItems = React.Children.toArray(children).length > 1;
  return children ? (
    <StyledColumn
      className={cx(
        'grid-column',
        type,
        directionMobile,
        hasMultipleItems && 'multiple-items'
      )}
      gap={gap}
      colWidth={colWidth}
      widthMobile={widthMobile}
      hasMultipleItems={hasMultipleItems}
    >
      {children}
    </StyledColumn>
  ) : null;
};

const Grid = { Row, Column };

export default Grid;
