import React from 'react';
import ThemeImage from '../ThemeImage';

const Image = props => {
  const { aspect, src, alt, cover } = props;

  return (
    <ThemeImage
      cover={cover}
      aspect={aspect || '1:1'}
      src={src}
      alt={alt}
      {...props}
    />
  );
};

export default Image;
