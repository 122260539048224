import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import React from 'react';
import { MaxWidthSmall } from '../../Layout/MaxWidth';
import { theme } from '../../Theme';

const BrandsCSS = `
  width: 100%;
  max-width: 53rem;

  > h2 {
    ${theme.mixins.mediumHeading}
    text-align: center;
    margin-bottom: 3rem;
  }

  > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > li {
      width: calc(33.333% - 1rem);
      flex: 0 1 auto;
      margin-bottom: 0.675rem;
      > a {
        color: ${theme.colors.primary};
      }
    }
    ${theme.below.md} {
      display: block;
      columns: 2;

      > li {
        width: 100%;
      }
    }
  }
`;

const Brands = styled(MaxWidthSmall)`
  ${BrandsCSS}
`;

const BrandList = ({ children }) => {
  return children ? (
    <Brands>
      <h2>{t('Our brands')}</h2>
      <ul>{children}</ul>
    </Brands>
  ) : null;
};

export default BrandList;
