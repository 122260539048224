import React, { useState, useEffect } from 'react';
import { HelloRetail as HelloRetailIndex } from '../HelloRetail';

const createBoxObj = (key, title) => (key ? { key, title } : null);

const HelloRetail = ({
  children,
  titleBox1 = '',
  keyBox1,
  titleBox2 = '',
  keyBox2,
  titleBox3 = '',
  keyBox3
}) => {
  const [boxes, setBoxes] = useState([]);

  useEffect(() => {
    const boxArray = [
      createBoxObj(keyBox1, titleBox1),
      createBoxObj(keyBox2, titleBox2),
      createBoxObj(keyBox3, titleBox3)
    ].filter(box => {
      return box;
    }, []);

    setBoxes(boxArray);
  }, [keyBox1, keyBox2, keyBox3, titleBox1, titleBox2, titleBox3]);

  if (boxes && boxes.length === 0) return null;

  return (
    <HelloRetailIndex helloRetailBoxes={boxes}>{children}</HelloRetailIndex>
  );
};

export default HelloRetail;
