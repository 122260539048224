import React from 'react';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import { css } from "linaria";
import t from '@jetshop/intl';
import ProductGrid from '../../CategoryPage/ProductGrid';
import categoryGridQuery from './CategoryGridQuery.gql';

const CategoryGridContainer = css`
  flex: 1 1 100%;
  max-width: 100%;

  h2 {
    text-align: center;
  }
`;

const CategoryProducts = ({ categoryId, title, showLink, limit = 4 }) => {
  const cat_id = Number(categoryId);

  return cat_id ? (
    <Query query={categoryGridQuery} variables={{ cat_id }}>
      {({ data, loading }) => {
        if (loading) return '';
        if (data && data.category && data.category.products.result) {
          const products = data.category.products.result;
          const productLength = products.length;
          return (
            <div className={CategoryGridContainer}>
              {title && <h2>{title}</h2>}
              {showLink && data.category.primaryRoute.path && (
                <Link to={data.category.primaryRoute.path}>
                  {t('Show all')}
                </Link>
              )}
              <ProductGrid
                products={limit ? products.slice(0, limit) : products}
                carousel={productLength > 4 && limit > 4}
                as={productLength > 4 && limit > 4 ? 'div' : 'ul'}
              />
            </div>
          );
        }
        return null;
      }}
    </Query>
  ) : null;
};

export default CategoryProducts;
