import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { theme } from '../../Theme';
import ThemeImage from '../../Theme/ThemeImage';
import ThemeLink from '../ThemeLink';

const Badge = css`
  position: absolute;
  right: 0;
  top: 1rem;
  padding: 0.275rem 0;
  padding-right: 1.5rem;
  padding-left: 0.5rem;
  background: ${theme.colors.pink};
  color: #000;

  > p {
    position: relative;
    left: 0;
    margin: 0;
    z-index: 2;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -1.25rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    z-index: 1;
  }

  &:before {
    top: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid ${theme.colors.pink};
  }

  &:after {
    bottom: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid ${theme.colors.pink};
  }
`;

const ImageWrapper = styled('div')`
  height: 100%;
  position: relative;
  color: ${theme.colors.white};
  ${theme.mixins.overlay}

  [data-flight-image-container] {
    padding-bottom: ${p => p.ratio}% !important;
    ${theme.below.md} {
      padding-bottom: ${p => p.ratio * 2}% !important;
    }
  }

  .multiple-items & {
    [data-flight-image-container] {
      padding-bottom: ${p => p.ratio}% !important;
    }
  }
`;

const Container = styled('div')`
  width: 100%;
  height: auto;
  position: absolute;
  padding: ${theme.space[2]};
  top: auto;
  bottom: 0px;
  left: 0px;
  z-index: 10;
  color: ${theme.colors.white};

  .title-anchor a {
    color: ${theme.colors.white};
    text-decoration: none;
  }

  ${theme.below.md} {
    width: 100%;
  }

  &[data-centerblock='true'] {
    left: 50%;
    text-align: center;
    top: 50%;
    bottom: unset;
    transform: translate(-50%, -50%);
  }

  > .pre-title {
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    font-weight: ${theme.fontWeights.medium};
  }

  &[data-specialblock='true'] {
    h2,
    h3 {
      font-family: ${theme.fonts.secondary};
      font-size: 6rem;
      line-height: 6.6875rem;
      ${theme.below.md} {
        font-size: 3rem;
        line-height: 3rem;
      }
    }
  }

  > h2,
  > h3 {
    font-size: 1.5rem;
    line-height: 1.625rem;
    font-weight: ${theme.fontWeights.medium};

    ${theme.below.md} {
      font-size: 1.625rem;
      line-height: 2rem;
    }
  }

  > .text-content {
    max-width: 440px;
    margin: 0 auto;
    line-height: 1.5rem;
  }

  > a {
    margin-top: 1.125rem;
  }
`;

const LinkContainer = styled(ThemeLink)`
  display: block;
`;

const TextOnImageWrapper = styled('div')`
  position: relative;
  height: 100%;
  > a {
    height: 100%;
  }
  &:hover {
    .ImageWrapper {
      &:after {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }
`;

const StoreFinderButton = ({
  children,
  preTitle,
  title,
  src = '#',
  url,
  buttonText,
  buttonUrl = '#',
  badgeText,
  center,
  aspect = '1:1',
  aspectMobile = false,
  cover,
  alt,
  special,
  titleType = 'h2'
}) => {
  let ratio = 0;
  if (aspect) {
    const ratioArray = aspect.split(':');
    if (ratioArray.length > 1) {
      ratio = (ratioArray[1] / ratioArray[0]) * 100;
    }
  }

  let LinkWrapper = Fragment;
  let linkWrapperProps = {};

  if (url) {
    LinkWrapper = LinkContainer;
    linkWrapperProps.link = url;
  }

  const Title = titleType;

  return (
    <TextOnImageWrapper>
      <LinkWrapper {...linkWrapperProps}>
        <ImageWrapper className="ImageWrapper" ratio={ratio}>
          <ThemeImage fillAvailableSpace src={src} alt={alt} cover={cover}>
            {badgeText && (
              <div className={Badge}>
                <p>{badgeText}</p>
              </div>
            )}
          </ThemeImage>
        </ImageWrapper>
      </LinkWrapper>
      <Container data-centerblock={center} data-specialblock={special}>
        {preTitle && <p className="pre-title">{preTitle}</p>}
        {title && buttonUrl ? (
          <Title className="title-anchor">
            {url ? <ThemeLink link={url}>{title}</ThemeLink> : title}
          </Title>
        ) : (
          <Title>{title}</Title>
        )}
        {children && <p className="text-content">{children}</p>}
        {buttonUrl && buttonText && (
          <ThemeLink link={buttonUrl} secondary isButton>
            {buttonText}
          </ThemeLink>
        )}
      </Container>
    </TextOnImageWrapper>
  );
};

export default StoreFinderButton;
