import AspectWrapper from './AspectWrapper';
import Banner from './Banner';
import Brand from './Brand';
import BrandList from './BrandList';
import CategoryImage from './CategoryImage';
import CategoryProducts from './CategoryProducts';
import Grid from './Grid';
import HelloRetail from './HelloRetail';
import HelloRetailRow from './HelloRetailRow';
import Image from './Image';
import LinkGroup from './LinkGroup';
import PartnerGroup from './PartnerGroup';
import Tag from './Tag';
import Text from './Text';
import TextOnImage from './TextOnImage';

const WindowedBlogCategoryPage = {
  AspectWrapper,
  Banner,
  Brand,
  BrandList,
  CategoryImage,
  CategoryProducts,
  ...Grid,
  HelloRetail,
  HelloRetailRow,
  Image,
  LinkGroup,
  PartnerGroup,
  Tag,
  Text,
  TextOnImage
};

export default WindowedBlogCategoryPage;
