import React from 'react';
import { css } from 'linaria';
import { Above } from '@jetshop/ui/Breakpoints';
import { TrendLink } from '../../ui/Button';
import ThemeImage from '../ThemeImage';
import { theme } from '../../Theme';

const CategoryImageCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  ${theme.mixins.overlay}

  > a {
    position: absolute;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    z-index: 5;
    &:hover {
      background: #fff;
      border: 1px solid #fff;
      color: ${theme.colors.primary};
    }
  }

  ${theme.below.md} {
    width: calc(100% - 0.5rem);
    margin-bottom: 0.25rem;
    &:after {
      display: none;
    }

    .grid-column:nth-child(even) & {
      margin-left: 0.25rem;
    }

    .grid-column:nth-child(odd) & {
      margin-right: 0.25rem;
    }

    > a {
      width: 100%;
      position: relative;
      background: ${theme.colors.primary};
      border: 1px solid ${theme.colors.primary};
      padding-right: 0;
      padding-left: 0;

      &:hover {
        background: ${theme.colors.primaryHover};
        border: 1px solid ${theme.colors.primaryHover};
        color: #fff;
      }
    }
  }
`;

const CategoryImage = ({ src, alt, buttonText, buttonUrl }) => {
  return (
    <div className={CategoryImageCSS}>
      <Above breakpoint="md">
        {matches =>
          matches && src && <ThemeImage src={src} alt={alt} aspect={'57:41'} />
        }
      </Above>
      {buttonUrl && buttonText && (
        <TrendLink to={buttonUrl} secondary>
          {buttonText}
        </TrendLink>
      )}
    </div>
  );
};

export default CategoryImage;
