import React from 'react';
import { css } from 'linaria';
import ThemeLink from '../ThemeLink';
import SetHTML from '../SetHTML';
import { ButtonStyleBasic } from '../../ui/Button';

const textCSS = css`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;
  > div {
    div[data-flight-image-container] + & {
      > h3 {
        margin-top: 1rem;
      }
    }

    > h3 {
      margin-bottom: 0.5rem;
      font-size: 1.625rem;
      line-height: 2rem;
    }
  }

  > a {
    display: inline-block;
    margin-top: 1.5rem;
    align-self: flex-start;
    text-decoration: none;
    ${ButtonStyleBasic}
  }
`;

const Text = ({ children, title, buttonUrl, buttonText }) => {
  return (
    <div className={textCSS}>
      <div>
        {title && <h3>{title}</h3>}
        {children && <SetHTML element="p" html={children} />}
      </div>
      {buttonUrl && buttonText && (
        <ThemeLink link={buttonUrl}>{buttonText}</ThemeLink>
      )}
    </div>
  );
};

export default Text;
