import { styled } from 'linaria/react';
import React from 'react';
import ProductGrid from '../../CategoryPage/ProductGrid';
import MaxWidth from '../../Layout/MaxWidth';
import { theme } from '../../Theme';

const Container = styled(MaxWidth)`
  flex: 1 1 100%;
  max-width: 100%;

  & + .hello-retail-row {
    margin-top: 4rem;
  }

  h2 {
    margin-bottom: 2rem;
    text-align: center;
  }
  ${theme.below.md} {
    padding: 0 0.1rem;
  }
`;

const HelloRetailBox = ({ helloRetailBox }) => {
  const isCarousel = helloRetailBox.products.length > 4;

  return (
    <Container className={'hello-retail-row'}>
      <div>
        {helloRetailBox.title && <h2>{helloRetailBox.title}</h2>}
        <ProductGrid
          products={helloRetailBox.products}
          carousel={isCarousel}
          as={isCarousel ? 'div' : 'ul'}
        />
      </div>
    </Container>
  );
};

export default HelloRetailBox;
