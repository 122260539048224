import React from 'react';
import { styled } from 'linaria/react';

const AspectWrapper = styled('div')`
  position: relative;
  padding-bottom: ${({ ratio }) => ratio}%;

  > * {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 50%;
    min-width: 280px;
    margin: auto;
  }
`;

const AspectWrapperComponent = ({ children, aspect }) => {
  let ratio = 25;

  if (aspect) {
    const ratioArray = aspect.split(':');
    if (ratioArray.length > 1) {
      ratio = (ratioArray[1] / ratioArray[0]) * 100;
    }
  }

  return <AspectWrapper ratio={ratio}>{children}</AspectWrapper>;
};

export default AspectWrapperComponent;
